
/* html {
  filter: grayscale(100%);
} */
body {
  margin: 0;
  font-family: "MicrosoftYaHei" !important;
  min-width: 1620px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


p {
  margin-bottom: 0px;
}

body,
pre,
div,
form,
img,
ul,
ol,
li,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  padding: 0;
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

td {
  max-width: 300px;
  overflow: hidden;
}

pre {
  font-family: MicrosoftYaHei;
}